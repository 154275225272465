import './Card.css';
import Tilt from 'react-parallax-tilt'
import {Front} from "./Front";

export const Card = () => {

    return <Tilt glareEnable={false} glareMaxOpacity={0.7} glareColor="white" glarePosition="all" perspective={5000} >
            <div className="flip-card">
                <Front/>
            </div>
        </Tilt>

}