import './Front.css'
import {FaLinkedin} from "react-icons/fa";
import {useMediaQuery} from "@mantine/hooks";

export const Front = () => {
    const matches = useMediaQuery('(min-width: 900px)');
    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100%'}}>
            <div style={{textAlign: 'center', flex: 1, paddingTop: 48, display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                    <div>
                        <p>first name</p>
                        <div style={{width: matches ? 170 : 110, height: 20, border: '2px solid #5d5d5d', borderBottom: 0, boxShadow: "1px -1px rgba(0, 0, 0, 0.3)"}}/>
                    </div>
                    <div style={{width: matches ? 60 : 45, height: 20}}/>
                    <div>
                        <p>last name</p>
                        <div style={{width: matches ? 160 : 105, height: 20, border: '2px solid #5d5d5d', borderBottom: 0, boxShadow: "1px -1px rgba(0, 0, 0, 0.3)"}}/>
                    </div>
                    <div style={{width: matches ? 80 : 60, height: 20}}/>
                </div>
                <a href="mailto:daniel@kuzin.dk"><h1>daniel@kuzin.dk</h1></a>
                <div>
                    <div style={{width: matches ? 480 : 330, height: 20, border: '2px solid #5d5d5d', borderTop: 0, boxShadow: "1px 1px rgba(0, 0, 0, 0.3)"}}/>
                    <p>e-mail</p>
                </div>
            </div>
            <div style={{ paddingBottom: 16, paddingLeft: 32, paddingRight: 32, display: 'flex', justifyContent: 'space-between'}}>
                <div className="social-item"><FaLinkedin size={30} color={'#5d5d5d'}/><a href="https://www.linkedin.com/in/daniel-kuzin/"><h2>daniel-kuzin</h2></a></div>
            </div>
        </div>
    )
}