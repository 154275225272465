import React from 'react';
import './App.css';
import {Card} from "./Card";
import {Front} from "./Front";
import {useMediaQuery} from "@mantine/hooks";

function App() {
  const matches = useMediaQuery('(min-width: 900px)');
  return (
    <div className="App">
        {matches ? <Card/> : <div className="Paper">
          <Front/>
        </div>}

    </div>
  );
}

export default App;
